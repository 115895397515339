* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
header {
  padding: 10px 40px;
  background-color: #fff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.09);
}
.ct_logo img {
  width: 80px;
}
.ct_input {
  height: 48px;
  border-radius: 5px;
  border: 1px solid #eee;
  color: black;
  font-weight: 500;
}
.ct_input:focus {
  box-shadow: unset;
  border-color: #febf29;
}
.ct_submit_btn {
  width: 200px;
  text-align: center;
  height: 48px;
  border-radius: 10px;
  outline: none;
  border: 1px solid #febf29;
  transition: 0.4s ease all;
  background-color: #febf29;
  color: #fff;
  margin-inline: auto;
  display: block;
}
.ct_submit_btn:hover {
  background-color: transparent;
  color: #000;
}
.ct_right_cnt ul {
  list-style: none;
  margin-bottom: 0px;
  padding-left: 0px;
}
.ct_right_cnt ul li + li {
  margin-top: 30px;
}

.ct_right_cnt ul li a {
  text-decoration: none;
  color: #000;
  transition: 0.4s ease all;
  font-weight: 400;
}
.ct_right_cnt ul li a:hover {
  color: #febf29;
}
.ct_fw_600 {
  font-weight: 600;
}
.ct_left_cnt,
.ct_right_cnt {
  background-color: #f5f5f5;
  padding: 30px;
  border-radius: 15px;
}
.ct_right_cnt {
  height: 100%;
}
.ct_fs_24 {
  font-size: 24px;
}
.ct_fs_20 {
  font-size: 20px;
}
.ct_right_cnt ul li h4 {
  border-left: 4px solid #febf29;
  padding-inline: 8px;
}
.ct_fs_28 {
  font-size: 28px;
}
@media screen and (max-width: 767px) {
  .ct_fs_28 {
    font-size: 24px;
  }
  header {
    padding-inline: 0px;
  }
}
