body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input.rmdp-input {
  height: 40px;
}

.ct_ant_modal_xl{
  max-width: 800px;
  width: 100% !important;
}

.ct_choose_cap_scroll_450{
  height: auto;
  max-height: 450px;
  overflow-y: auto;
}
/* width */
.ct_choose_cap_scroll_450::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.ct_choose_cap_scroll_450::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.ct_choose_cap_scroll_450::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.ct_choose_cap_scroll_450::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.ct_active_card_hover:hover{
  border: 1px solid #fea92a;
  cursor: pointer;
}

input.rmdp-input {
  height: 48px;
  text-indent: 35px;
  width: 100% !important;
  background-color: #f3f3f3;
  border: 0px;
}
.rmdp-container {
  width: 100%;
}

/* .ct_log_out_btn{
  background-color: #ff0000 !important;
} */
 .ct_log_out_btn{
  background-color: transparent;
  border: 0px;
  outline: none;
  color: #ff0000;
 }
 .ct_menu_list{
  margin-left: auto;
 }