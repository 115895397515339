.ride-tabs-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .tabs-header {
    display: flex;
    justify-content: space-around;
    background: #f8f9fa;
    padding: 10px;
  }
  
  .tabs-header button {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    background: #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .tabs-header .active-tab {
    background: #007bff;
    color: white;
  }
  
  .ride-content {
    display: flex;
    height: 500px;
    margin-top: 10px;
  }
  
  .ride-list {
    width: 40%;
    border-right: 1px solid #ddd;
    overflow-y: auto;
  }
  
  .search-bar {
    width: 90%;
    margin: 10px auto;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .ride-item {
    padding: 15px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
  }
  
  .ride-item.active {
    background: #f1f8ff;
  }
  
  .ride-details {
    flex: 1;
    padding: 20px;
  }
  
  .no-ride-selected {
    color: #888;
    text-align: center;
    margin-top: 20px;
  }
  
  .ct_date_picker_text_indent {
    width: 100%;
}
.ct_date_picker_text_indent input.rmdp-input {
  text-indent: 0px;
  width: 183px !important;
  padding-inline: 10px;
}

@media screen and (max-width:575px){
  .ct_date_picker_text_indent input.rmdp-input {
  
    width: 100% !important;
 
}
}