@import url("https://fonts.googleapis.com/css2?family=Radio+Canada:ital,wght@0,300..700;1,300..700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  box-sizing: border-box;

  padding: 0px;

  margin: 0px;
}

body {
  overflow-x: hidden;

  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Radio Canada", sans-serif;
}

ul {
  padding-left: 0px;

  margin-bottom: 0px;
}

section {
  overflow-x: hidden;
}

a,
a:hover {
  text-decoration: none;
}

:root {
  --yellow: #fea92a;
}

p {
  font-family: "Inter", sans-serif;
}

.ct_navbar {
  display: flex;

  align-items: center;

  justify-content: space-between;

  padding: 0px 30px;
}

.ct_navbar li {
  display: inline-block;

  margin-inline: 5px;

  margin-inline: 15px;
}

.ct_navbar li a {
  padding: 6px 0px;

  color: #000;

  font-weight: 500;

  font-size: 18px;

  transition: 0.4s ease all;

  width: fit-content;

  position: relative;
}

.ct_navbar li a:before {
  content: "";

  position: absolute;

  left: 0px;

  width: 0%;

  height: 2px;

  background-color: var(--yellow);

  bottom: 0px;

  transition: 0.4s ease all;
}

.ct_navbar li a:hover:before {
  width: 100%;
}

.ct_logo img {
  width: 90px;

  object-fit: contain;
}

header {
  background-color: #fff;
}

.ct_toggle_bar,
.ct_close_bar {
  display: none;

  transition: 0.4s ease all;
}

/* Home slider css S */

.ct_dowload_apps img {
  width: 180px !important;
}

.ct_dowload_apps {
  display: flex;

  align-items: center;

  gap: 15px;

  justify-content: center;

  margin-top: 35px;
}

.ct_hero_slider_bnr {
  position: relative;

  overflow: hidden;

  z-index: 1;

  height: 87vh;
}

.ct_hero_slider_bnr:before {
  content: "";

  position: absolute;

  inset: 0px;

  background-image: url(./images/hero_overlay_4-1.png);

  z-index: -1;

  width: 100%;

  height: 100vh;
}

.ct_hero_slider_bnr_cnt {
  position: absolute;

  top: 50%;

  transform: translate(-50%, -50%);

  width: 100%;

  color: #fff;

  left: 50%;

  text-align: center;

  max-width: 800px;
}

.ct_hero_slider .owl-item.active .ct_hero_slider_bnr_cnt {
  animation: slide_down 1s ease;
}

@keyframes slide_down {
  from {
    top: 40%;

    opacity: 0;
  }

  to {
    top: 50%;

    opacity: 1;
  }
}

.ct_hero_slider_bnr_cnt h1 {
  font-size: 80px;

  font-weight: 900;
}

.ct_hero_slider_bnr_cnt h2 {
  font-size: 40px;

  font-weight: 300;

  margin-bottom: 15px;
}

.ct_hero_slider_bnr_cnt p {
  line-height: 28.2px;
}

.ct_hero_slider .owl-nav {
  display: none;
}

.ct_hero_slider .owl-dots {
  position: absolute;

  bottom: 30px;

  left: 50%;

  transform: translateX(-50%);

  display: flex;

  align-items: end;
}

.ct_hero_slider .owl-dots .owl-dot {
  width: 12px;

  height: 12px;

  background-color: #fff;

  border-radius: 100px;

  margin-inline: 6px;

  transition: 0.4s ease all;
}

.ct_hero_slider .owl-dots .owl-dot.active {
  height: 25px;

  background-color: var(--yellow);
}

/* About Section S */

.ct_sec_padd {
  padding-block: 100px;
}

.ct_about_sub_head {
  font-size: 18px;

  font-weight: 500;

  color: var(--yellow);

  margin-bottom: 20px;

  position: relative;
}

h2.ct_h2_head {
  font-weight: 700;

  line-height: 1.5;

  font-size: 44px;
}

.ct_para_p {
  font-size: 16px;

  line-height: 26px;
}

.ct_cabty_about_img img {
  animation: bg-move 30s linear infinite forwards,
    border-move 15s linear infinite forwards;

  width: 100%;
}

@keyframes border-move {
  0% {
    border-radius: 51% 49% 34% 66% / 39% 66% 34% 61%;
  }

  10% {
    border-radius: 39% 61% 38% 62% / 53% 54% 46% 47%;
  }

  20% {
    border-radius: 56% 44% 52% 48% / 57% 35% 65% 43%;
  }

  30% {
    border-radius: 41% 59% 45% 55% / 33% 56% 44% 67%;
  }

  40% {
    border-radius: 46% 54% 60% 40% / 55% 74% 26% 45%;
  }

  50% {
    border-radius: 44% 56% 22% 78% / 31% 74% 26% 69%;
  }

  60% {
    border-radius: 31% 69% 32% 68% / 31% 71% 29% 69%;
  }

  70% {
    border-radius: 44% 56% 60% 40% / 63% 36% 64% 37%;
  }

  80% {
    border-radius: 46% 54% 38% 62% / 44% 48% 52% 56%;
  }

  90% {
    border-radius: 66% 34% 57% 43% / 49% 51% 49% 51%;
  }

  100% {
    border-radius: 51% 49% 34% 66% / 39% 66% 34% 61%;
  }
}

/* services Section S */

.ct_services_bg {
  background-image: url("./images/feature_bg_1-1.jpg");

  background-position: center;

  background-size: cover;
}

.ct_service_box {
  margin-bottom: 0px;

  background-color: #fff;

  text-align: center;

  padding: 40px 30px;

  border-radius: 10px;

  overflow: hidden;

  position: relative;

  transition: 0.4s ease all;

  z-index: 1;

  height: 100%;
}

.ct_service_box:hover {
  background-color: #000;

  transform: translateY(-15px);
}

.ct_service_box:before {
  content: "";

  background-color: #000;

  width: 101%;

  height: 0%;

  position: absolute;

  left: 0px;

  top: 0px;

  z-index: -1;

  transition: 0.6s ease all;

  border-radius: 10px;
}

.ct_service_box:hover:before {
  height: 101%;
}

.ct_service_box:hover h4,
.ct_service_box:hover p {
  color: #fff;
}

.ct_service_icon {
  position: relative;

  z-index: 1;

  width: 100px;

  height: 100px;

  margin: auto;

  margin-bottom: 32px;

  display: flex;

  align-items: center;

  justify-content: center;

  background-color: var(--yellow);

  border-radius: 100px;
}

.ct_service_icon img {
  filter: brightness(0) invert(1);

  transition: 0.5s ease;
}

.ct_service_box p {
  line-height: 28px;
}

.ct_service_box figcaption p ~ img {
  position: absolute;

  right: -10px;

  width: 100px;

  height: 100px;

  bottom: -10px;

  transform: scale(0);

  transition: 0.4s ease all;

  opacity: 0.1;

  filter: brightness(221.5);
}

.ct_service_box:hover figcaption p ~ img {
  transform: scale(1);

  opacity: 0.1;
}

.ct_service_icon {
  transition: 0.4s ease all;
}

.ct_service_box:hover .ct_service_icon {
  transform: translateY(-15px);
}

.ct_app_download_bg {
  background-image: url(./images/download_bg_3-1-1.jpg);

  background-position: center;

  background-size: cover;

  padding: 100px 50px;

  position: relative;

  z-index: 1;
}

.ct_app_download_bg:before {
  content: "";

  position: absolute;

  top: 0px;

  left: 0px;

  width: 100%;

  height: 100%;

  background-color: #f7f7f7f5;

  z-index: -1;

  opacity: 1;
}

.ct_app_store_12 img {
  width: 100%;
}

/* Contact Form Css S */

.ct_inputContainer {
  position: relative;

  display: flex;

  flex-direction: column;

  gap: 10px;
}

#ct_inputField {
  border: 2px solid #eee;

  background-color: transparent;

  border-radius: 10px;

  padding: 12px 15px;

  color: black;

  font-weight: 500;

  outline: none;

  caret-color: var(--yellow);

  transition-duration: 0.3s;

  font-family: Whitney, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica, Arial, sans-serif;
}

.userIcon {
  position: absolute;

  fill: var(--yellow);

  width: 12px;

  top: -23px;

  left: -15px;

  opacity: 0;

  transition: 0.2s linear;
}

.usernameLabel {
  position: absolute;

  top: -28px;

  left: 5px;

  color: #000;

  font-size: 16px;

  font-weight: 600;

  font-family: "Inter", sans-serif;

  overflow: hidden;

  transition: 0.2s linear;

  opacity: 0;
}

#ct_inputField:focus ~ .usernameLabel,
#ct_inputField:valid ~ .usernameLabel {
  transform: translateX(20px);

  opacity: 1;
}

#ct_inputField:focus ~ .userIcon,
#ct_inputField:valid ~ .userIcon {
  transform: translateX(20px);

  opacity: 1;
}

#ct_inputField:focus,
#ct_inputField:valid {
  background-color: #ddd;

  transition-duration: 0.3s;
}

.ct_custom_btn {
  padding: 6px 15px;

  border-radius: 100px;

  background-color: var(--yellow);

  color: #fff;

  position: relative;

  z-index: 1;

  transition: 0.3s ease all;

  overflow: hidden;

  height: 48px;

  font-size: 16px;

  font-weight: 600;

  width: 200px;

  border: 1px solid var(--yellow);

  margin-inline: auto;

  display: block;
}

.ct_custom_btn:before {
  content: "";

  position: absolute;

  left: 0px;

  top: 0px;

  background-color: #000;

  z-index: -1;

  width: 0%;

  height: 100%;

  transition: 0.4s ease all;
}

.ct_custom_btn:hover {
  border-color: #000;
  color: #fff;
}

.ct_custom_btn:hover:before {
  width: 100%;
}

/* footer css S */

footer {
  background-color: #1b1b1b;

  padding: 100px 0px;
}

.ct_footer_logo img {
  width: 80px;

  object-fit: contain;

  margin-bottom: 15px;
}

.ct_footer_logo p {
  color: #fff;

  width: 90%;
}

.ct_social_media_links {
  display: flex;

  align-items: center;

  gap: 15px;
}

.ct_social_media_links a {
  width: 50px;

  height: 50px;

  display: flex;

  align-items: center;

  justify-content: center;

  color: #fff;

  background-color: #3d4250;

  position: relative;

  z-index: 1;

  transition: 0.4s ease all;
}

.ct_social_media_links a:before {
  content: "";

  position: absolute;

  top: 0px;

  left: 0px;

  background-color: var(--yellow);

  width: 100%;

  height: 2px;

  transition: 0.4s ease all;

  z-index: -1;
}

.ct_social_media_links a i {
  transition: 0.4s ease all;
}

.ct_social_media_links a:hover i {
  transform: rotate(360deg);
}

.ct_social_media_links a:hover:before {
  height: 100%;
}

.ct_footer_link h4 {
  color: #fff;

  font-size: 24px;

  font-weight: 600;

  margin-bottom: 30px;
}

.ct_footer_link ul li + li {
  margin-top: 15px;
}

.ct_footer_link ul li a {
  color: #fff;

  text-decoration: none;

  transition: 0.4s ease;

  position: relative;

  width: fit-content;

  padding-bottom: 6px;

  font-weight: 500;
}

.ct_footer_link ul li a:before {
  content: "";

  position: absolute;

  left: 0px;

  width: 0%;

  height: 2px;

  background-color: var(--yellow);

  bottom: 0px;

  transition: 0.4s ease all;
}

.ct_footer_link ul li a:hover:before {
  width: 100%;
}

.ct_footer_link h6 {
  color: #fff;

  margin-bottom: 15px;
}

.ct_footer_info i {
  width: 30px;

  height: 30px;

  background: var(--yellow);

  display: inline-flex;

  align-items: center;

  justify-content: center;

  color: #000;

  margin-right: 10px;
}

.ct_news_letter_div p {
  color: #fff;

  line-height: 1.8;

  font-size: 15px;
}

.ct_news_leeter_btn input {
  background-color: #2c3240;

  width: 100%;

  height: 48px;

  border: 1px solid #2c3240;

  padding: 6px 10px;

  outline: none;

  margin-bottom: 15px;
}

.ct_subfooter {
  background-color: #0b0b0b;

  padding: 25px 0px;
}

.ct_subfooter p {
  color: #fff;

  text-align: center;
}

.ct_subfooter a {
  color: var(--yellow);
}

.ct_footer_car_trackimg {
  width: 100%;

  height: 30px;

  position: relative;

  animation: move_car 15s linear infinite;

  background-position: bottom right;

  overflow: hidden;
}

.ct_footer_car_track img {
  transition: 2s ease all;

  left: 30px;

  position: relative;

  overflow: hidden;
}

.ct_loader {
  position: fixed;

  width: 100%;

  background-color: #fff;

  top: 0px;

  left: 0px;

  height: 100%;

  z-index: 999;

  display: flex;

  align-items: center;

  justify-content: center;
}

.ct_loader img {
  width: 250px;
}

* {
  box-sizing: border-box;

  padding: 0px;

  margin: 0px;
}

@keyframes move_car {
  from {
    background-position: bottom right;
  }

  to {
    background-position: bottom left;
  }
}

.ct_download_from_qr {
  display: flex;

  align-items: center;

  gap: 15px;
}

.ct_download_from_qr img {
  width: 80px !important;
}

.ct_download_from_qr h4 {
  font-size: 16px;

  margin-bottom: 5px;

  color: #000;
}

.ct_download_from_qr p {
  font-size: 12px;

  color: #000;

  opacity: 0.5;
}

.ct_inner_bg {
  background-image: url(./images/safty_cab.jpg);

  height: 500px;

  background-size: cover;

  background-position: center 22%;

  position: relative;

  z-index: 1;
}

.ct_inner_bg:before {
  content: "";

  position: absolute;

  inset: 0px;

  background-color: #000;

  opacity: 0.7;

  z-index: -1;
}

.ct_inner_bg h1 {
  font-size: 60px;

  font-weight: 800;

  margin-bottom: 15px;
}

.ct_fs_35 {
  font-weight: 700;

  font-size: 35px;
}

.ct_safty_img img {
  width: 100%;

  height: 400px;

  border-radius: 10px;
}

ul.safety_tips_list {
  list-style: none;
}

.safety_tips_list li {
  font-size: 16px;

  display: flex;

  align-items: center;

  gap: 10px;
}

.safety_tips_list li i {
  color: var(--yellow);
}

.safety_tips_list li + li {
  margin-top: 10px;
}

.ct_service_box a {
  color: #fea92a;
}

/* New Cabty Landing pag Design S */
.cb_nav_tab li button {
  background-color: transparent;
  outline: none;
  border: 1px solid transparent;
}
.cb_nav_tab > ul {
  display: flex;
  gap: 96px;
}
.cb_nav_tab li .cb_tab_icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  margin-inline: auto;
}
.cb_nav_tab li button.active .cb_tab_icon,
.cb_nav_tab li button:hover .cb_tab_icon {
  background-color: rgb(232, 232, 232);
}
.cb_nav_tab li button.active span,
.cb_nav_tab li button:hover span {
  opacity: 1;
}
.cb_nav_tab li span {
  color: #000;
  opacity: 0.6;
  display: block;
  margin-top: 4px;
}
.cb_fs_52 {
  font-size: 52px;
}
.cb_py_60 {
  padding-block: 60px;
}
.cb_input_icon_2 {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}
.cb_text_indent_30 {
  text-indent: 30px;
}
.ct_input_grey {
  background-color: #f3f3f3 !important;
}
.cb_location_dropdown {
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.09);
  z-index: 9;
  width: 100%;
  padding: 16px;
  border-radius: 10px;
  margin-top: 3px;
  border: 1px solid #eee;
  max-height: 300px;
  overflow-y: auto;
  height: auto;
}
/* width */
.cb_location_dropdown::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.cb_location_dropdown::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.cb_location_dropdown::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.cb_location_dropdown::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.cb_location_dropdown li {
  display: grid;
  align-items: center;
  grid-template-columns: 32px auto;
  gap: 15px;
}
.cb_location_dropdown li + li {
  border-top: 1px solid #eee;
  padding-top: 14px;
  margin-top: 14px;
}
.cb_city_icon {
  width: 32px;
  height: 32px;
  border-radius: 100px;
  background-color: rgb(232, 232, 232);
  display: flex;
  align-items: center;
  justify-content: center;
}
.cb_location_name_title {
  overflow: hidden;
}
.cb_location_name_title h5 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  box-sizing: border-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
}
.cb_border_radius_10 {
  border-radius: 10px;
}
.cb_decoration_line {
  position: relative;
}
.cb_decoration_line:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 1px;
  background-color: #000;
  position: absolute;
  top: 0px;
  left: 24px;
  z-index: 1;
  height: calc(100% - 65px);
  bottom: 0px;
  margin-block: auto;
}
.cb_grey_btn {
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 100px;
  color: #000;
}
.cb_suggestion_card_grey {
  background-color: #f3f3f3;
  border-radius: 10px;
  padding: 16px;
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 2%;
  align-items: center;
}
.cb_suggestion_img img {
  max-width: 150px;
  width: 100%;
  object-fit: contain;
}
.cb_link_underline {
  position: relative;
  z-index: 1;
  color: #000;
  padding-bottom: 5px;
  border-bottom: 1px solid #eee;
}
.cb_link_underline:after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 0%;
  height: 1px;
  background-color: #000;
  transition: 0.4s ease all;
}
.cb_link_underline:hover:after {
  width: 100%;
}
.cb_how_work_cnt_2 {
  position: relative;
  height: 100%;
}
.cb_how_work_cnt_2::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #000;
  left: -50px;
}
.cb_how_work_cnt_2::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: #000;
  left: -45px;
  top: 0px;
}
.cb_drive_img img {
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
  height: 450px;
}

#ct_logout_modal .modal-content .modal-body svg {
  width: 50px;
  height: 50px;
  margin-inline: auto;
  display: block;
  margin-bottom: 20px;
  top: 0;
}
.ct_logout_text_modal:hover{
  color: rgb(220, 53, 69);
}


.ct_log_out_modal svg {
  width: 60px;
  height: 60px;
  margin-inline: auto;
  display: block;
  margin-bottom: 20px;
}
.ct_log_out_modal h4{
  font-size: 28px;
  margin-bottom: 10px !important;
}
.ct_btn_red:hover{
  color: #ff0000;
  background-color: transparent !important;
  border-color: #ff0000;
}
.ct_cancle_btn{
  background-color: #E6E6E6;
  color: #000;
  border-color: #e6e6e6;
}
.ct_cancle_btn:hover{
  border-color: var(--yellow);
  color: var(--yellow);
}



/* New Cabty Landing pag Design E */

@media screen and (max-width: 1199px) {
  .cb_fs_52 {
    font-size: 40px;
  }
}
@media screen and (max-width: 991px) {
  .ct_dowload_apps {
    flex-wrap: wrap;
  }
  .ct_menu_list ul {
    position: fixed;

    width: 300px;

    background-color: #000;

    right: -100%;

    top: 0px;

    padding: 80px 30px;

    height: 100vh;

    z-index: 999;

    transition: 0.4s ease all;
  }

  .ct_hero_slider_bnr_cnt {
    max-width: 100%;

    padding-inline: 30px;
  }

  .ct_menu_list ul.active {
    right: 0px;
  }

  .ct_navbar li {
    display: block;
  }

  .ct_navbar li + li {
    margin-top: 15px;
  }

  .ct_navbar li a {
    color: #fff;
  }

  .ct_toggle_bar,
  .ct_close_bar {
    display: block;

    transition: 0.4s ease all;
  }

  .ct_close_bar {
    position: absolute;

    right: 30px;

    top: 30px;

    color: #fff;

    font-size: 20px;
  }
}

@media screen and (max-width: 767px) {
  .ct_hero_slider_bnr_cnt h2 {
    font-size: 20px;
  }
  .ct_dowload_apps img {
    width: 126px !important;
  }
  .ct_hero_slider_bnr {
    height: 100vh;
  }

  .ct_hero_slider_bnr_cnt h1 {
    font-size: 35px;
  }

  h2.ct_h2_head {
    font-size: 28px;
  }

  .ct_row_reverse {
    flex-direction: column-reverse;
  }
  .ct_res_relative {
    position: relative;
  }
  .cb_how_work_cnt_2 {
    position: unset;
  }
}
