.loader {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.car__body {
  -webkit-animation: shake 0.2s ease-in-out infinite alternate;
  animation: shake 0.2s ease-in-out infinite alternate;
}

.car__line {
  transform-origin: center right;
  stroke-dasharray: 22;
  -webkit-animation: line 0.8s ease-in-out infinite;
  animation: line 0.8s ease-in-out infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.car__line--top {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.car__line--middle {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.car__line--bottom {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

@-webkit-keyframes shake {
  0% {
    transform: translateY(-1%);
  }

  100% {
    transform: translateY(3%);
  }
}

@keyframes shake {
  0% {
    transform: translateY(-1%);
  }

  100% {
    transform: translateY(3%);
  }
}

@-webkit-keyframes line {
  0% {
    stroke-dashoffset: 22;
  }

  25% {
    stroke-dashoffset: 22;
  }

  50% {
    stroke-dashoffset: 0;
  }

  51% {
    stroke-dashoffset: 0;
  }

  80% {
    stroke-dashoffset: -22;
  }

  100% {
    stroke-dashoffset: -22;
  }
}

@keyframes line {
  0% {
    stroke-dashoffset: 22;
  }

  25% {
    stroke-dashoffset: 22;
  }

  50% {
    stroke-dashoffset: 0;
  }

  51% {
    stroke-dashoffset: 0;
  }

  80% {
    stroke-dashoffset: -22;
  }

  100% {
    stroke-dashoffset: -22;
  }
}

.ant-message.ant-message-top.css-dev-only-do-not-override-qgg3xn {
  z-index: 9999999999;
}

.ct_header_bg {
  position: fixed;
  width: 100%;
  z-index: 99 !important;
  left: 0px;
  right: 0px;
  max-width: 88%;
  margin-inline: auto;
}

a.ct_yellow_btn {
  font-size: 14px;
  background-color: #fea92a;
  color: #fff;
  padding: 6px 8px;
  border-radius: 8px;
  font-weight: 400;
  display: block;
}

.ct_rides_detail_box {
  /* box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.18);
  background-color: #fff;
  padding: 18px 15px;
  border-radius: 30px; */
  margin-left: 15%;
}

#ct_reassign_modal button.btn-close {
  width: 30px;
  height: 30px;
  background-color: #d8d8d8c9;
  border-radius: 100px;
  background-image: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-right: 15px;
}
div#ct_reassign_modal .modal-content {
  border-radius: 30px;
}

#ct_reassign_modal button.btn-close:focus {
  box-shadow: none;
}
#ct_reassign_modal button.btn-close svg {
  width: 23px;
  height: 23px;
}
#ct_reassign_modal .ct_user_img_circle img {
  width: 50px;
  height: 50px;
}

.ct_fs_141 {
  font-size: 13px;
}
#ct_reassign_modal .ct_active_ride_card {
  padding-block: 10px;
}

div#ct_reassign_modal .modal-content {
  border-radius: 30px;
  height: 582px;
  overflow-y: auto;
}

/* .ct_modal_backgrop ~ .modal-backdrop.fade.show {
  opacity: 0;
  z-index: -1;
} */
/* .ct_modal_backgrop ~ .modal-backdrop.fade{
  opacity: 0;
} */

.ct_add_custom_drop:before {
  content: "";
  position: absolute;
  width: 100%;
  background-color: #000;
  opacity: 0.5;
  height: 100vh;
}

.ct_add_category12 {
  display: flex;
  align-items: end;
  gap: 5px;
}

.ct_add_category12 button.ct_custome_btn {
  border-radius: 10px;
  width: auto;
}
.ct_flex_1 {
  flex: 1;
}

.ct_hero_slider .owl-dots .owl-dot span {
  display: none !important;
}

.cb_setting_tab ul li a {
  background-color: #f5f5f5;
  padding: 6px 15px;
  border-radius: 10px;
  color: #000;
  transition: 0.4s ease all;
}
.cb_setting_tab ul {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 40px;
  justify-content: center;
  margin-top: 24px;
}
.cb_setting_tab ul li a.active,
.cb_setting_tab ul li a:hover {
  background-color: #fea92a;
  color: #fff;
}

.cb_text_normal {
  white-space: normal;
}



select.ct_pagination_select_21 {
  height: 38px !important;
  border-radius: 5px !important;
  text-align: center;
  border: 1px solid #c5c2c2 !important;
}
.previous a {
  color: #000;
  font-size: 14px;
}
.next a {
  color: #000;
  font-size: 14px;
}


select.ct_pagination_select_21 {
  height: 38px !important;
  border-radius: 5px !important;
  text-align: center;
  border: 1px solid #c5c2c2 !important;
}
.pagination li.previous,
.pagination li.next {
  width: auto;
  background-color: transparent;
}
.previous a {
  color: #000;
  font-size: 14px;
}
.next a {
  color: #000;
  font-size: 14px;
}
.pagination {
  display: flex;
  align-items: center;
  gap: 10px;
}
.pagination li.active {
  background-color: #fea92a;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}
.pagination li.active a {
  color: #fff;
}
.pagination li {
  background-color: #f5f5f5;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}
.pagination li a {
  color: #000;
}
